// Main Style
@import './layout/common.scss';

// --------------------------------------------------------

/* Vendor */

// --------------------------------------------------------

/* Component / Common */
%bg-deco {
	position: absolute;
	z-index: -1;
	background: no-repeat left top / contain;
	pointer-events: none;

	&::before {
		content: '';
		display: block;
	}
}

// --------------------------------------------------------

/* Container */
// Banner
.index-banner {
	$root: &;

	--banner-pd-y: calc(var(--sec-pd-y) + #{rem-calc(50 + 75)});
	--cont-gap: #{rem-calc(10)};

	padding-top: var(--hd-height);

	&__bg {
		&__img {
			background-size: cover;
			background-position: 90% center;
		}
	}

	&__r {
		text-align: center;
	}

	&__item-cont {
		--pd-x: 0;

		padding: 0 var(--pd-x);
	}

	@at-root %cont-gap {
		margin-bottom: var(--cont-gap);
	}

	&__title {
		@extend %cont-gap;

		@include breakpoint(medium) {
			font-size: rem-calc(48);
		}
	}

	&__subtitle {
		@extend %cont-gap;
	}

	&__btn {
		@extend %cont-gap;
	}

	&__link-list {
		display: flex;
		gap: rem-calc(10);
		text-align: center;
		line-height: 1.2;

		&__link {
			--pd-x: #{rem-calc(10)};
			--pd-y: #{rem-calc(10)};
			--radius: #{rem-calc(15)};

			display: block;
			padding: var(--pd-y) var(--pd-y);
			height: 100%;
			background: $white;
			color: $black;
			border-radius: var(--radius);
			box-shadow: 0px 1px 14px 5px rgba(0, 0, 0, 0.15);
		}

		&__icon {
			display: block;
			margin: 0 auto rem-calc(4);
		}
	}

	&__item {
		&.type-normal {
			#{$root} {
				&__img-box {
					border-radius: 25px;
					box-shadow: 0px 1px 9px 4px rgba(0, 0, 0, 0.15);
					background: $white;

					&::before {
						@include before-img-size(684, 460);
					}
				}
			}
		}

		&.type-img-lottie {
			#{$root} {
				&__img-box {
					position: relative;

					&::before {
						@include before-img-size(775, 580);
					}
				}
			}

			lottie-player {
				position: absolute;
				top: 0;
				left: 0;
				width: 100% !important;
				height: 100% !important;
			}
		}

		&.type-img-sprite {
			#{$root} {
				&__img-box {
					position: relative;

					&::before {
						content: '';
						display: block;
						padding-top: calc(var(--height, 580) / var(--width, 750) * 100%);
					}
				}

				&__img {
					background: transparent no-repeat top center;
					background-size: 100%;
				}
			}

			&.swiper-slide-active {
				#{$root} {
					&__img {
						animation: sprite-ani var(--sec, 1s) steps(calc(var(--frame) - 1)) infinite;
					}

					@keyframes sprite-ani {
						0% {
							background-position: 0 0;
						}

						100% {
							background-position: 0 100%;
						}
					}
				}
			}
		}
	}

	.swiper-wrapper {
		--pd-y: #{rem-calc(35)};

		padding: var(--pd-y) 0;
	}

	.swiper-pagination {
		--pd: #{rem-calc(4)};

		margin: calc(var(--pd) * -1);
		padding: var(--pd);
	}

	.swiper-pagination-bullet {
		--swiper-pagination-color: #{$white};

		box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
	}

	@include breakpoint(small only) {
		&__link-list {
			&__link {
				font-size: rem-calc(14);
			}
		}
	}

	@include breakpoint(medium down) {
		--swiper-pagination-bottom: 0;

		&__link-list {
			justify-content: center;
			margin-top: rem-calc(20);
			width: 100%;

			&.pc {
				display: none;
			}

			&__icon {
				max-width: 70%;
			}
		}

		.swiper-pagination-bullet {
			--swiper-pagination-bottom: 0;
		}
	}

	@include breakpoint(medium) {
		--cont-gap: #{rem-calc(20)};

		&__item-cont {
			--pd-x: #{rem-calc(40)};
		}

		.swiper-wrapper {
			--pd-y: #{rem-calc(50)};
		}

		.swiper-pagination-bullet {
			--swiper-pagination-bullet-size: #{rem-calc(15)};
		}
	}

	@include breakpoint(large) {
		padding-top: rem-calc(50 + 75);

		&__l {
			margin-right: rem-calc(50);
		}

		&__item-cont {
			padding-left: calc(var(--pd-x) * 1.7);
		}

		&__link-list {
			&.mobile {
				display: none;
			}

			&__item {
				flex: 1;
			}

			&__link {
				--radius: #{rem-calc(25)};
			}

			&__icon {
				margin-bottom: rem-calc(10);
			}
		}
	}

	@include breakpoint(large only) {
		.swiper-wrapper {
			padding-bottom: calc(var(--pd-y) + #{rem-calc(50)});
		}
	}

	@include breakpoint(xlarge) {
		&__item-cont {
			--pd-x: #{rem-calc(80)};
		}

		&__link-list {
			gap: rem-calc(25);

			&__link {
				--pd-x: #{rem-calc(10)};
				--pd-y: #{rem-calc(18)};
				--radius: #{rem-calc(25)};

				// min-width: rem-calc(200);
			}

			&__icon {
				margin-bottom: rem-calc(10);
			}
		}
	}
}

.index-banner {
	/* Animation */
	/* stylelint-disable */
	$animate-el: '.front, .middle',
	'.back',
	'.sky',
	'.index-banner__title',
	'.index-banner__subtitle',
	'.index-banner__btn',
	'.index-banner__link-list__item:nth-child(1)',
	'.index-banner__link-list__item:nth-child(2)',
	'.index-banner__link-list__item:nth-child(3)',
	'.index-banner__r',
	'.swiper-button-prev, .swiper-button-next, .swiper-pagination';

	&.js-ani {
		@include set-animate-default($animate-el);

		.front {
			transform: translateY(10%)
		}

		.middle {
			transform: translateY(5%)
		}

		&:not(.is-animated) {
			@include set-animate-from($animate-el);
		}

		&.is-animated {
			.front {
				transform: translateY(calc(var(--ws, 0px) * -0.01));
			}

			.middle {
				transform: translateY(calc(var(--ws, 0px) * -0.05));
			}

			.back {
				transform: translateY(calc(var(--ws, 0px) * 0.1));
			}

			.sky {
				transform: translateY(calc(var(--ws, 0px) * 0.3));
			}
		}
	}

	/* stylelint-enable */
}

.has-scrolled {

	.front,
	.middle,
	.back,
	.sky {
		transition-duration: 0s !important;
		transition-delay: 0s !important;
	}
}

// ----------------------------
// 五大安心保證
.index-assure {
	$root: &;

	--mask-size: 30px;
	--sec-pd-y: #{rem-calc(60)};

	z-index: 1;
	margin-top: calc(var(--mask-size) * -1); // 回收 mask 高度
	@include breakpoint(medium only) {
		--sec-pd-y: #{rem-calc(120)};
	}
	@include breakpoint(large) {
		--mask-size: 50px;
		--sec-pd-y: #{rem-calc(200)};

		padding-bottom: calc(var(--sec-pd-y) + #{rem-calc(100)});
	}

	&__bg-box {
		z-index: -1;
		background-color: $white;
		mask:
			url($mask-up-2) no-repeat top center / 100% var(--mask-size),
			linear-gradient(to bottom, #000, #000) no-repeat bottom center / 100% calc(100% - var(--mask-size) + 1px);
	}

	&__bg-img {
		background: no-repeat center center / cover;

		@include breakpoint(small only) {
			background-size: 150% 100%;
		}
	}

	&.entered {
		#{$root} {
			&__bg-img {
				background-image: url('../images/index/bg-assure.png');
			}
		}
	}

	@include breakpoint(medium down) {
		.sec-title {
			margin-top: rem-calc(109);
		}
	}

	&__title-deco {
		position: absolute;


		@include breakpoint(medium down) {
			left: 50%;
			bottom: 100%;
			transform: translate(-50%, 0);
		}

		@include breakpoint(large) {
			top: 50%;
			left: -20px;
			transform: translate(-100%, -50%);
		}
	}

	&__list {
		display: grid;
		gap: rem-calc(20);
		grid-template-columns: repeat(5, 1fr);
	}

	&__item {
		position: relative;
	}

	&__card {
		--pd-x: #{rem-calc(20)};
		--pd-y: #{rem-calc(35)};
		--radius: 25px;

		position: relative;
		padding: var(--pd-y) var(--pd-x);
		text-align: center;
		background: $white;
		box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.15);

		@include breakpoint(small only) {
			width: round(245 / 375 * 100vw);
		}

		@include breakpoint(medium only) {
			--pd-x: #{rem-calc(40)};

			width: 40vw;
		}

		@include breakpoint(medium) {
			--pd-y: #{rem-calc(65)};
		}

		@include breakpoint(large only) {
			width: 28vw;
		}

		@include breakpoint(xlarge only) {
			--pd-x: #{rem-calc(10)};
		}

		@include breakpoint(xlarge only) {
			--pd-y: #{rem-calc(35)};
		}
	}

	&__icon {
		--icon-bg: #{$linear-orange-200};

		position: absolute;
		left: 50%;
		top: 0;
		transform: translate(-50%, -50%);
		border: 3px solid $white;
	}

	&__sp-title {
		display: none;
	}

	&__title {
		margin-bottom: em-calc(20 / 32);
		color: $primary-color;
	}

	&__slogan {
		margin-bottom: 0;
		font-weight: 300;
		color: $gray-400;
	}

	&__recommend {
		margin-top: rem-calc(10);

		a {
			display: block;
			font-weight: 700;
			color: $black;
			padding: rem-calc(5 0);
			line-height: 1.6;

			@include breakpoint-hover {

				&:hover,
				&:focus {
					color: $primary-color;
				}
			}
		}
	}

	// 裝飾
	&__float-circle-1 {
		top: -12%;
		left: 19%;
	}

	&__float-circle-2 {
		bottom: -13%;
		left: 10%;
	}

	&__float-circle-3 {
		top: 36%;
		right: 3%;
	}

	&__line {
		left: 50%;
		top: 0;

		@include breakpoint(small only) {
			height: 100px;
		}
	}

	@include breakpoint(large down) {
		&__cont {
			--pd-y: #{rem-calc(30)};

			display: flex; // 為了增加右邊的邊距寬度
			margin: calc(var(--pd-y) / -1) calc(var(--pd-x) / -1);
			padding: calc(var(--pd-y) * 2) var(--pd-x);
			overflow-x: auto;

			// 為了增加右邊的邊距寬度
			&::after {
				content: '';
				display: inline-block;
				width: var(--pd-x);
				height: 1px;
			}
		}

		&__card {
			height: 100%;
		}

		&__title-box {
			position: absolute;
			left: 50%;
			top: 0;
			padding: rem-calc(5 25 5 15);
			display: flex;
			align-items: center;
			background: $linear-orange-200;
			border: 3px solid $white;
			border-radius: 99px;
			transform: translate(-50%, -50%);
		}

		&__title {
			font-size: rem-calc(16);
			font-weight: 500;
			color: $white;
			white-space: nowrap;
			margin-bottom: 0;
		}

		&__icon {
			--icon-bg: transparent;
			--size: 35px;

			position: static;
			transform: none;
			border: 0 none;
		}

		&__slogan {
			display: none;
		}
	}

	@include breakpoint(xlarge) {
		&__desc-box {
			max-height: 0;
			overflow: hidden;
			p{
				line-height: 1.6;
			}
		}

		@at-root %open-style {
			&::before {
				content: "";
				display: block;
				width: 100%;
			}

			#{$root} {
				&__card {
					position: absolute;
					top: 50%;
					width: 100%;
					transform: translateY(-50%);
					transform-origin: top center;
					animation: showCard 0.3s;
				}

				&__title-box {
					position: absolute;
					left: 50%;
					top: 0;
					padding: rem-calc(3 25 7 15);
					display: flex;
					align-items: center;
					background: $linear-orange-200;
					border: 3px solid $white;
					border-radius: 99px;
					transform: translate(-50%, -50%);
				}

				&__icon {
					--icon-bg: transparent;
					--size: 35px;

					position: static;
					transform: none;
					border: 0 none;
				}

				&__sp-title {
					display: block;
					font-size: rem-calc(16);
					font-weight: 500;
					color: $white;
					white-space: nowrap;
					margin-bottom: 0;
				}

				&__title,
				&__slogan {
					display: none;
				}

				&__desc-box {
					max-height: 200px;
				}
			}

			@keyframes showCard {
				0% {
					transform: scaleY(0) translateY(-50%);
				}

				100% {
					transform: scaleY(1) translateY(-50%);
				}
			}
		}

		&__list {
			margin-top: rem-calc(135);
		}

		&__item {

			&:hover,
			&:focus-within {
				@extend %open-style;
			}

			// For Mobile
			&.is-active {
				@extend %open-style;
			}
		}
	}

	@at-root .is-tablet {
		#{$root} {
			.float-circle {
				animation: none; //- STI #14 - 為了避免iPad裝置無法正常顯示Mask，要停止mask以內(bg-box內)的動畫物件(circle-1)
			}
		}
	}
}

.index-assure {
	/* Animation */
	/* stylelint-disable */
	$animate-el: '.index-assure__bg-img',
	'.float-circle-2',
	'.index-assure__line',
	'.sec-title__title',
	'.sec-title__deco',
	'.sec-title__desc',
	'.index-assure__item:nth-child(1)',
	'.index-assure__item:nth-child(2)',
	'.index-assure__item:nth-child(3)',
	'.index-assure__item:nth-child(4)',
	'.index-assure__item:nth-child(5)';

	&.js-ani {
		@include set-animate($animate-el);

		.sec-title {
			--delay: 1.5s;
		}

		.sec-title__title span {
			transform: rotateY(90deg);
		}

		.float-circle,
		.index-assure__title-deco {
			opacity: 0;
			transition-duration: 1s;
			transition-property: opacity, transform;
			transition-delay: 1.2s;
		}

		.float-circle {
			transition-delay: 0.3s;
		}

		&.is-animated {
			@include set-animated($animate-el);

			.float-circle {
				opacity: 1;
			}

			.sec-title__title span {
				transform: rotateY(0deg);
			}

			.index-assure__title-deco {
				opacity: 1;
			}
		}
	}

	/* stylelint-enable */
}

// ----------------------------
// 設計師作品
.index-proj {
	$root: &;

	position: relative;
	z-index: 1;
	padding-top: 0;

	--mask-size: #{rem-calc(40)};
	--mg: calc(var(--mask-size) * -1);

	@include breakpoint(medium down) {
		padding-bottom: rem-calc(150);
	}

	@include breakpoint(large) {
		--mask-size: #{rem-calc(90)};

		margin-top: var(--mg); // 回收 mask 高度
		padding-bottom: calc(var(--sec-pd-y) + #{rem-calc(50)});
	}

	&__bg-box {
		z-index: -1;
		background: #fff;
		color: #fff;
		mask:
			url($mask-up-2) no-repeat top center / 100% var(--mask-size),
			linear-gradient(to bottom, #000, #000) no-repeat center center / 100% calc(100% - var(--mask-size) * 2 + 2px),
			url($mask-down-1) no-repeat bottom center / 100% var(--mask-size);

		// 放置圖片
		&__bg {
			background-color: #ccc;
			background-size: cover;
			opacity: 0.2;
			filter: blur(5px);
		}
	}

	// 搜尋Bar
	&__search {
		--radius: 8px;

		margin-bottom: rem-calc(45);
		font-size: rem-calc(14);
		background: $white;
		border-radius: var(--radius);
		box-shadow: 0px 1px 14px 2px rgba(0, 0, 0, 0.08);

		&__select-group {
			--pd-x: 0;
			--pd-y: #{rem-calc(3)};

			display: flex;
			padding: var(--pd-y) var(--pd-x);
		}


		&__select-box {
			position: relative;
			flex: 1;

			&::before {
				--line-height: #{rem-calc(20)};

				content: '';
				position: absolute;
				top: 50%;
				right: 0;
				margin-top: calc(var(--line-height) / -2);
				width: 1px;
				height: var(--line-height);
				background: $gray-300;
			}

			&:last-child {
				&::before {
					display: none;
				}
			}
		}

		&__select {
			border-color: transparent !important;
			background-color: transparent;
			margin-bottom: 0;
			padding-left: rem-calc(14);
			font-size: rem-calc(14);
		}

		&__btn-submit {
			--pd-y: #{rem-calc(14)};

			margin-bottom: 0;
			width: 100%;
			padding-top: var(--pd-y);
			padding-bottom: var(--pd-y);
			font-size: em-calc(16 / 14);
			border-radius: 0 0 var(--radius) var(--radius);

			i {
				font-size: em-calc(25 / 16);
				vertical-align: middle;
			}
		}

		@include breakpoint(large) {
			--radius: 99px;

			display: flex;
			align-items: stretch;
			margin: 0 auto rem-calc(100);
			max-width: rem-calc(1100);
			font-size: rem-calc(20);

			&__select-group {
				--pd-x: #{rem-calc(10)};
				--pd-y: #{rem-calc(15)};

				flex: 1;
			}

			&__select-box {
				&::before {
					--line-height: #{rem-calc(35)};
				}
			}

			&__select {
				padding-left: rem-calc(20);
				font-size: rem-calc(20);
			}

			&__btn-submit {
				flex: 0 0 260px;
				border-bottom-left-radius: 0;
				border-top-right-radius: var(--radius);
				font-size: 1em;
			}
		}
	}

	&__gallery {

		.swiper {
			border-radius: 8px;
			box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.15);

			@include breakpoint(medium down) {
				margin-bottom: rem-calc(25);
			}

			@include breakpoint(medium) {
				border-radius: 25px;
			}
		}

		&__item {
			&::before {
				@include before-img-size(684, 460)
			}
		}
	}

	@at-root %proj-cont {
		--mg-b: #{rem-calc(10)};

		position: relative;
		z-index: 5;

		.title,
		.subtitle,
		.desc-box {

			margin-bottom: var(--mg-b);
		}

		.subtitle {
			color: $gray-400;
			font-weight: 700;
		}

		.hashtag {
			font-size: rem-calc(14);

			li {
				display: inline-block;
				margin-right: rem-calc(20 - 14 / 3);
			}

			a {
				color: inherit;

				@include breakpoint-hover {

					&:hover,
					&:focus {
						color: $primary-color;
					}
				}
			}
		}

		@include breakpoint(medium) {
			--mg-b: #{rem-calc(30)};
		}
	}

	&__text-box {
		@extend %proj-cont;
	}

	&__designer {
		$el: &;
		$circle-size: 185px;

		--circle-size: 30vw;

		margin-top: rem-calc(80);

		@include breakpoint(medium) {
			--circle-size: #{rem-calc(185)};

			margin-top: rem-calc(120);
		}

		@include breakpoint(large) {
			margin-top: rem-calc(40);
		}

		@at-root %designer-transition {
			transition: transform 0.5s, opacity 0.5s;
		}

		&__item {
			position: relative;
			width: var(--circle-size);

			// Active 裝飾圓圈
			&::before,
			&::after {
				--size: #{percentage(200px / $circle-size)};

				content: '';
				position: absolute;
				z-index: 1;
				width: var(--size);
				height: var(--size);
				border-radius: 100%;
				border: 1px solid #F3B3A0;
			}

			&::before {
				top: percentage(-10px / $circle-size);
				left: 0;
			}

			&::after {
				top: percentage(-20px / $circle-size);
				right: 0;
				border-style: dashed;
			}
		}

		// Active 裝飾
		&__deco {
			@extend %designer-transition;

			position: absolute;
			right: 15%;
			bottom: 15%;
			width: percentage(125px / $circle-size);
			height: percentage(120px / $circle-size);
			transform: translate(50%, 50%);
			transform-origin: left top;
		}

		&__photo-box {
			border-radius: 100%;
		}

		&__name {
			@extend %designer-transition;

			position: absolute;
			z-index: 4;
			bottom: 0;
			left: 50%;
			display: inline-block;
			min-width: #{rem-calc(100)};
			text-align: center;
			background: $white;
			color: $black;
			border-radius: 99px;
			box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.15);
			transform: translate(-50%, 50%) scale(var(--sx, 1), var(--sy, 1));
		}

		&__icon {
			@extend %designer-transition;

			--size: #{rem-calc(40)};
			--icon-bg: #{$primary-light-2};

			position: absolute;
			z-index: 5;
			top: 0;
			left: 0;
		}

		// 給JS要讀取的內容
		&__cont {
			display: none;

			@extend %proj-cont;
		}

		.swiper-wrapper {
			--pd-t: #{rem-calc(100)};
			--pd-b: #{rem-calc(50)};

			margin-top: calc(var(--pd-t) * -1);
			margin-bottom: calc(var(--pd-b) * -1);
			padding-top: var(--pd-t);
			padding-bottom: var(--pd-b);

			@include breakpoint(large) {
				--pd-t: #{rem-calc(50)};
			}
		}

		.swiper-slide {
			overflow: visible !important;

			&:not(.swiper-slide-active) {

				@at-root %desiner-hide-item {
					opacity: 0;
					pointer-events: none;
				}

				&::before,
				&::after {
					@extend %desiner-hide-item;
				}

				#{$el} {

					&__icon,
					&__deco {
						@extend %desiner-hide-item;

						transform: scale(0);
					}

					&__name {
						--sx: 0;
					}

					&__photo {
						filter: grayscale(1);
					}
				}

				@include breakpoint-hover {
					cursor: pointer;

					#{$el} {
						&__photo {
							transition: all 0.5s;
						}
					}

					&:hover,
					&:focus {
						#{$el} {
							&__photo {
								filter: grayscale(0.5);
								transform: scale(1.2);
							}
						}
					}
				}
			}
		}
	}
}

.index-proj {
	/* Animation */
	/* stylelint-disable */
	$animate-el: '.index-proj__gallery',
	'.title',
	'.subtitle',
	'.desc-box, .hashtag',
	'.index-proj__designer';

	&.js-ani {
		@include set-animate($animate-el);

		&.is-animated {
			@include set-animated($animate-el);
		}
	}

	/* stylelint-enable */
}

// ----------------------------
// 新聞
.index-news {
	$root: &;

	&__l {
		@include breakpoint(large) {
			padding-right: rem-calc(60);
		}
	}

	&__r {
		margin-top: rem-calc(40);

		@include breakpoint(large) {
			max-width: 540px;
		}
	}

	// 左邊圖
	&__img-box-wrapper {
		--pos: 15%;

		position: relative;
		margin-left: auto;
		max-width: 615px;

		@include breakpoint(medium only) {
			margin-right: auto;
			width: 80%;
			max-width: 400px;
		}
	}

	&__img-box {
		overflow: hidden;
		border-radius: 100%;
		box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.15);
	}

	&__tag {
		top: var(--pos);
		right: 8%;
	}

	.btn-like {
		top: var(--pos);
		left: var(--pos);

		@include breakpoint(medium) {
			font-size: rem-calc(65);
		}
	}

	// 裝飾指標
	&__img-arrow {
		position: absolute;
		color: $primary-light-1;
		background: currentColor;

		&::before {
			--size: 6px;

			content: '';
			position: absolute;
			width: var(--size);
			height: var(--size);
			background: currentColor;
			border-radius: 9px;
		}

		@include breakpoint(medium down) {
			bottom: -30px;
			left: 50%;
			width: 1px;
			height: 70px;

			&::before {
				bottom: 0;
				left: calc(var(--size) / -2);
			}
		}

		@include breakpoint(medium) {
			&::before {
				--size: 10px;
			}
		}

		@include breakpoint(large) {
			top: 13%;
			right: -10%;
			width: 190px;
			height: 1px;

			&::before {
				top: calc(var(--size) / -2);
				right: 0;
			}
		}
	}

	// 右邊新聞
	&__list {
		margin-bottom: rem-calc(40);

		@include breakpoint(medium) {
			margin-bottom: rem-calc(60);
		}
	}

	&__item {
		--pd-y: #{rem-calc(15)};

		padding: var(--pd-y) 0;
		border-bottom: 1px solid;
		border-image: linear-gradient(to right, #BEBEBE, #D9DCE3 20%, rgba(#D9DCE3, 0) 100%) 1;
	}

	&__link {
		--size: 25px; // Arrow-size

		position: relative;
		display: flex;
		color: $black;

		@include breakpoint(medium) {
			--size: 35px; // Arrow-size
		}

		&::after {
			@include font-icon-add(arrow-right);

			position: absolute;
			top: 50%;
			right: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: calc(var(--size) / -2);
			width: var(--size);
			height: var(--size);
			color: $gray-300;
			border: 1px solid currentColor;
			border-radius: 100%;
			font-size: calc(var(--size) * #{(20 / 35)});
		}
	}

	&__date {
		--size: #{rem-calc(50)};
		--radius: 8px;

		flex-direction: column;
		flex: 0 0 var(--size);
		margin-right: rem-calc(10);
		height: var(--size);
		font-weight: 500;
		text-align: center;
		color: $primary-color;
		border: 2px solid currentColor;

		@at-root %dateText {
			display: block;
			line-height: 1.2;
			margin-bottom: 0
		}

		&__month {
			@extend %dateText;
		}

		&__date {
			@extend %dateText;

			font-size: em-calc(24 / 16);
			font-weight: 700;
		}

		@include breakpoint(small only) {
			font-size: rem-calc(14);
		}

		@include breakpoint(medium) {
			--size: #{rem-calc(60)};

			margin-right: rem-calc(20);
		}
	}

	&__info-box {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;

		@include breakpoint(small only) {
			font-size: rem-calc(14);
		}
	}

	&__title {
		margin-bottom: 0;
	}

	&__desc {
		margin-top: rem-calc(10);
		margin-bottom: 0;
		color: #333;

		@include breakpoint(medium) {
			margin-top: rem-calc(20);
		}
	}

	&__item {

		// 非第一個項目
		&:not(:first-child) {
			#{$root} {
				&__date {
					color: $gray-300;
				}

				&__info-box {
					padding-right: calc(var(--size) + #{rem-calc(10)});
				}

				&__title {
					font-size: rem-calc(16);
					font-weight: 500;
					color: $gray-400;

					@include breakpoint(small only) {
						font-size: rem-calc(14);
					}
				}
			}
		}

		// 第一個項目
		&:first-child {
			#{$root} {
				&__link {
					&::after {
						display: none;
					}
				}
			}
		}
	}

	// 裝飾(球)
	&__float-circle {
		left: -3%;
		top: 30%;
	}

	// 裝飾(左)
	&__bg-deco1 {
		@extend %bg-deco;

		left: 0;
		top: -14%;
		width: 50vw;
		max-width: 501px;

		&::before {
			@include before-img-size(501, 610, false);
		}

		&.entered {
			background-image: url('../images/index/bg-news-r.png');
		}
	}

	// 裝飾(右)
	&__bg-deco2 {
		@extend %bg-deco;

		right: 0;
		top: 30%;
		width: 433px;

		&::before {
			@include before-img-size(433, 1332, false);
		}

		&.entered {
			background-image: url('../images/index/bg-news-l.png');
		}

		@include breakpoint(small only) {
			display: none;
		}
	}

	@include breakpoint-hover {
		&__img {
			transition: transform 0.5s;
		}

		&__img-box {

			&:hover,
			&:focus {
				#{$root} {
					&__img {
						transform: scale(1.1);
					}
				}
			}
		}

		&__link {

			&:hover,
			&:focus {
				@at-root %color-change {
					color: $primary-color;
				}

				&::after {
					@extend %color-change;

					--ani-to-x: 20%;

					animation: float 1s infinite;
				}

				#{$root} {
					&__title {
						@extend %color-change;
					}
				}
			}
		}
	}
}

.index-news {
	/* Animation */
	/* stylelint-disable */
	$animate-el: '.index-news__bg-deco1, index-news__bg-deco2',
	'.float-circle',
	'.sec-title__title',
	'.sec-title__desc',
	'.index-news__l',
	'.index-news__r',
	'.index-news__item:nth-child(2), .index-news__item:nth-child(3), .index-news__item:nth-child(4)',
	'.btn-box';

	&.js-ani {
		@include set-animate($animate-el);

		.sec-title {
			--delay: 0.9s;
		}

		.sec-title__title span {
			transform: rotateY(90deg);
		}

		&.is-animated {
			@include set-animated($animate-el);

			.sec-title__title span {
				transform: rotateY(0deg);
			}
		}
	}

	/* stylelint-enable */
}

// ----------------------------
// 文章
.index-article {
	$root: &;
	$img-width: 380;
	$img-height: 260;

	--gap: #{rem-calc(45)}; // 計算按鈕位置使用
	--col: 1; // 計算按鈕位置使用
	--pd-t: #{rem-calc(20)}; // 為了顯示陰影區塊內容

	@include breakpoint(small only) {
		--pd-x: #{rem-calc(30)};
	}

	@include breakpoint(medium) {
		--col: 2;
	}

	@include breakpoint(large) {
		--col: 3;
	}

	&__item {
		position: relative;

		@at-root .swiper:not(.swiper-initialized) {
			@include breakpoint(large) {
				#{$root} {
					&__item {
						margin-right: rem-calc(45);
						width: 33.33%;
					}
				}
			}
		}
	}

	&__link {
		display: block;
		color: $black;
	}

	&__img-box {
		--radius: 25px;

		position: relative;
		box-shadow: 0px 1px 9px 4px rgba(0, 0, 0, 0.15);
		overflow: hidden;

		&::before {
			@include before-img-size($img-width, $img-height);
		}
	}

	&__text-box {
		padding: rem-calc(15 0);

		@include breakpoint(medium) {
			padding-left: rem-calc(40);
		}
	}

	&__date {
		font-size: rem-calc(12);
		margin-bottom: em-calc(9 / 12);
	}

	// 更多 Hover 才出現
	&__more {
		display: none;
	}

	.tag {
		right: -5px;
	}

	.btn-like {
		top: 15px;
		left: 15px;
	}

	// 按鈕位置設置
	.swiper-box {
		--swiper-nav-pos: 0;
		--swiper-navigation-top-offset: calc((((100vw - (var(--pd-x-global) + var(--pd-x)) * 2 - var(--gap) * (var(--col) - 1)) / var(--col)) * #{$img-height / $img-width}) / 2 + var(--pd-t));

		@include breakpoint(medium) {
			--swiper-navigation-top-offset: calc((((100vw - var(--pd-x-global) * 2 - var(--gap) * (var(--col) - 1)) / var(--col)) * #{$img-height / $img-width}) / 2 + var(--pd-t));
		}

		@include breakpoint($global-width) {
			--swiper-navigation-top-offset: calc(#{rem-calc(300 / 2)} + var(--pd-t)); // 圖片高 / 2
		}
	}

	.swiper-cont-expand {
		@include breakpoint(small only) {
			--pd-x: #{rem-calc(30)};
		}
	}

	.swiper-wrapper {
		margin-top: calc(var(--pd-t) / -1); // 回收高度
		padding-top: var(--pd-t); // 為了顯示陰影
	}

	.swiper-slide {
		opacity: 0.6;
		transition: opacity 0.5s;
	}

	.swiper-slide-active,
	.swiper-slide-next,
	.swiper-slide-next+.swiper-slide {
		opacity: 1;
	}

	@include breakpoint-hover {
		&__link {
			padding-bottom: rem-calc(96);
		}

		&__img {
			transition: transform 0.5s;
		}

		&__text-box {
			position: absolute;
			z-index: 5;
			padding-left: rem-calc(20);
			width: 100%;
			transition: all 0.3s;
			border-top-left-radius: 15px;
			margin-left: rem-calc(20);
		}

		&__title {
			@include clamp($line-limit: 2, $line-height: 1.4);
		}

		// 更多 Hover 才出現
		&__more {
			--circle-size: #{rem-calc(65)};

			position: relative;
			display: block;
			margin-top: rem-calc(35);
			margin-left: rem-calc(80 + 10 - 20);
			width: var(--circle-size);
			height: var(--circle-size);
			font-size: rem-calc(14);
			line-height: var(--circle-size);
			text-align: center;
			color: $orange;
			border: 1px solid currentColor;
			border-radius: 100%;
			opacity: 0;
			transform: translateX(-90px);
			transition: transform 0.5s, opacity 0.5s;

			&::before {
				content: '';
				position: absolute;
				right: calc(100% + 10px);
				top: 50%;
				width: 80px;
				height: 1px;
				background: currentColor;
				transform: scaleX(0);
				transform-origin: right center;
				transition: transform 0.5s;
			}

			&::after {
				content: '';
				position: absolute;
				top: calc(50% + 0.75em);
				left: calc(50% - 1.1em);
				width: 0.8em;
				height: 1px;
				background: currentColor;
			}
		}

		&__link {

			&:hover,
			&:focus {
				#{$root} {
					&__title {
						--line-limit: 2;

						color: $black;
					}

					&__img {
						transform: scale(1.1);
					}

					&__text-box {
						background: $white;
						transform: translateY(calc(-100% + #{rem-calc(90)}));

						&::before {
							background: $white;
							transform: scaleY(1);
						}
					}

					&__more {
						opacity: 1;
						transform: none;

						&::before {
							transform: scaleX(1)
						}
					}
				}
			}
		}
	}

	// 裝飾
	&__bg-deco {
		@extend %bg-deco;

		left: 0;
		top: 11%;
		width: 29vw;
		max-width: 448px;

		&::before {
			@include before-img-size(448, 897, false);
		}

		&.entered {
			background-image: url('../images/index/bg-article.png');
		}

		@include breakpoint(medium) {
			top: -14%;
		}
	}
}


.index-article {
	/* Animation */
	/* stylelint-disable */
	$animate-el: '.index-article__bg-deco',
	'.sec-title__title',
	'.sec-title__desc',
	'.index-article__item:nth-child(1) .index-article__link',
	'.index-article__item:nth-child(2) .index-article__link',
	'.index-article__item:nth-child(3) .index-article__link',
	'.index-article__item:nth-child(4) .index-article__link',
	'.swiper-navigation',
	'.btn-box';

	&.js-ani {
		@include set-animate-default($animate-el);

		.sec-title {
			--delay: 0.8s;
		}

		.sec-title__title span {
			transform: rotateY(90deg);
		}

		&:not(.is-animated) {
			@include set-animate-from($animate-el);
		}

		&.is-animated {
			.sec-title__title span {
				transform: rotateY(0deg);
			}
		}
	}

	/* stylelint-enable */
}

// ----------------------------
// 看法/評論
.index-review {
	&__l {
		margin-top: rem-calc(-10); // 回收 &__text-box padding-top

		@include breakpoint(small only) {
			margin-bottom: rem-calc(20);
		}

		@include breakpoint(medium) {
			display: flex;
			align-items: center;

			&__cont {
				width: 100%;
			}
		}
	}

	// Title
	.sec-title {
		@include breakpoint(medium) {
			text-align: left;
		}
	}

	&__text-box {
		position: relative;
		padding-top: rem-calc(10);
		text-indent: 2em;

		@include breakpoint(small only) {
			font-size: rem-calc(14);
		}

		// 左上裝飾
		&::before {
			content: '“';
			position: absolute;
			top: 0;
			left: 0;
			color: #FFD447;
			font-size: rem-calc(64);
			font-weight: 700;
			line-height: 1;
			text-indent: initial;
			transform: translate(-60%, 0);
		}
	}

	&__user {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: rem-calc(8);
		padding-right: rem-calc(130);
		color: $pink;

		&__profile {
			margin-right: rem-calc(15);
			background-color: $white;
			border: 2px solid currentColor;
			border-radius: 100%;
		}

		&__name {
			font-weight: 500;
		}
	}

	// Swiper
	.swiper-pagination {
		--swiper-pagination-bottom: 8px;
		--swiper-pagination-bullet-size: 5px;
		--swiper-pagination-bullet-inactive-color: #bebebe;

		text-align: right;
	}

	.swiper-pagination-bullet-active {
		--swiper-pagination-bullet-size: 8px;

		position: relative;
		width: var(--swiper-pagination-bullet-size);
		color: $pink;
		background: currentColor;
		transform: translateY(1.5px);

		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: calc(100% + 8px);
			width: 45px;
			height: 1px;
			display: inline-block;
			background: currentColor;
		}

		&:not(:last-child) {
			margin-right: rem-calc(45 + 8 + 8);
		}

		&:last-child {
			margin-left: rem-calc(45 + 8 + 8);

			&::after {
				left: auto;
				right: calc(100% + 8px);
			}
		}
	}

	.swiper-pagination-bullet {
		@include breakpoint-hover {

			&:hover,
			&:focus {
				background-color: $primary-color;
			}
		}
	}

	// 裝飾
	&__float-circle {
		left: -1%;
		bottom: 0;
	}

	&__l {
		position: relative;

		&::before,
		&::after {
			--circle-size: #{rem-calc(530)};
			--size: var(--circle-size);
			--mg: calc(var(--size) / -2);
			--ani-to-y: 10%;

			content: '';
			position: absolute;
			display: block;
			left: 50%;
			top: 50%;
			z-index: -1;
			margin-top: var(--mg);
			margin-left: var(--mg);
			width: var(--size);
			height: var(--size);
			border-radius: 100%;
			background: $linear-light-yellow;
			opacity: 0.6;
			animation: float 5s linear infinite;
		}

		&::before {
			--size: calc(var(--circle-size) * 1.2);

			left: 80%;
			opacity: 0.3;
			animation-delay: 0.5s;
		}

		@include breakpoint(medium) {
			--circle-size: #{rem-calc(480)};
		}
	}
}


.index-review {
	/* Animation */
	/* stylelint-disable */
	$animate-el: '.float-circle',
	'.index-review__l::before',
	'.index-review__l::after',
	'.index-review__r',
	'.sec-title__title',
	'.swiper-box',
	'.index-review__user, .swiper-pagination';

	&.js-ani {
		@include set-animate($animate-el);

		.sec-title {
			--delay: 1.8s;
		}

		.sec-title__title span {
			transform: rotateY(90deg);
		}

		&.is-animated {
			@include set-animated($animate-el);

			.sec-title__title span {
				transform: rotateY(0deg);
			}

			.index-review__l {

				&::before,
				&::after {
					opacity: 0.6;
				}
			}
		}
	}

	/* stylelint-enable */
}

// ----------------------------
// 合作夥伴
.index-partner {
	$root: &;

	&__item {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: rem-calc(20);
		height: 80px;
		background: $gray-0;
		border-radius: rem-calc(15);

		@include breakpoint(small only) {
			width: round(180 / 375 * 100vw);
			max-width: 200px;
		}

		@include breakpoint(medium) {
			padding: rem-calc(30);
			height: 120px;
		}
	}

	&__img {
		filter: grayscale(1);
	}

	@include breakpoint-hover {
		&__item {
			&:hover {
				#{$root} {
					&__img {
						filter: grayscale(0);
					}
				}
			}
		}
	}
}

.index-partner {
	/* Animation */
	/* stylelint-disable */
	$animate-el: '.sec-title__title',
	'.sec-title__desc',
	'.swiper-box',
	'.swiper-button-prev, .swiper-button-next';

	&.js-ani {
		@include set-animate-default($animate-el);

		.sec-title {
			--delay: 0.3s;
		}

		&:not(.is-animated) {
			@include set-animate-from($animate-el);

			.sec-title__title span {
				transform: rotateY(90deg);
			}
		}
	}

	/* stylelint-enable */
}
